import React from 'react';
import ExpandableCard from '../../Utils/ExpandableCard';

// Importa las imágenes locales
// import image1 from '../../Image/Blog/chico.jpg';
// import image2 from '../../Image/Blog/2.jpg';
import image3 from '../../Image/Blog/3.jpg';

const ForoInicio = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    width: '100%',
  };

  // const text1 = "Elegir sillas cómodas y ergonómicas es crucial para mantener la comodidad y la salud durante largas horas de trabajo. Opta por sillas ergonómicas que ofrezcan altura regulable para personalizar la silla a tu postura y aumentar tu productividad. Los materiales duraderos, como la malla resistente, garantizan una larga vida útil, mientras que las bases de metal aseguran la estabilidad necesaria para un uso prolongado. Encuentra las mejores sillas para oficina y transforma tu espacio de trabajo con calidad y confort.";
  // const text2 = "Los juguetes desempeñan un papel esencial en el desarrollo infantil. En particular, los juguetes que permiten a los niños simular profesiones como doctor, veterinario y cocinero no solo proporcionan entretenimiento, sino que también actúan como herramientas educativas clave. Estos juguetes promueven el crecimiento y el aprendizaje de los niños, fomentando habilidades importantes mientras juegan.";
  const text3 = `Incorporar lockers en espacios de trabajo y almacenamiento es una excelente solución para mejorar tanto la seguridad como la organización en cualquier entorno laboral. 
  Los lockers proporcionan un lugar seguro y privado donde los empleados pueden guardar sus pertenencias personales, lo que reduce significativamente el riesgo de robo o pérdida de objetos valiosos. 
  Este tipo de almacenamiento no solo protege los objetos personales, sino que también contribuye a crear un ambiente de trabajo más ordenado y eficiente. Al tener un espacio dedicado para cada trabajador, se minimiza el desorden en las áreas comunes, lo que facilita la movilidad y el acceso a las herramientas o documentos necesarios para realizar tareas diarias.
  
  Además, los lockers fomentan la confidencialidad, ya que cada empleado puede guardar sus pertenencias de manera segura, sin preocuparse por la invasión de su privacidad. Este aspecto es especialmente importante en trabajos que manejan información sensible o confidencial. 
  Otro beneficio clave es que, al contar con un sistema de almacenamiento organizado, las empresas pueden aprovechar mejor su espacio, optimizando el uso de áreas compartidas y mejorando la estética general del lugar.
  
  En resumen, los lockers no solo proporcionan una solución práctica para mantener las pertenencias organizadas, sino que también contribuyen a mejorar la productividad, seguridad y bienestar de los empleados. 
  Por estas razones, invertir en lockers es una excelente opción para cualquier empresa que busque crear un ambiente de trabajo más eficiente y cómodo.`;
  
  return (
    <div style={containerStyle}>
      {/* <ExpandableCard
        title="Sillas Ergonómicas: Comodidad y Durabilidad para Tu Oficina"
        imageSrc={image1} // Usar imagen local
        text={text1}
      /> */}
      {/* <ExpandableCard
        title="Simulando Profesiones: Juguetes Educativos para Niños"
        imageSrc={image2} // Usar imagen local
        text={text2}
      /> */}
      <ExpandableCard
        title="Beneficios de tener lockers en espacios de trabajo y almacenamiento"
        imageSrc={image3} // Usar imagen local
        text={text3}
      />
    </div>
  );
};

export default ForoInicio;
