import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

function Aprobado() {
  const navigate = useNavigate();
  const [, setPaymentData] = useState(null);

  useEffect(() => {
    const enviarDatosAlBackend = async (url, paymentData, compraID = null) => {
      if (!paymentData || !paymentData.productos) {
        console.error('Error: paymentData o paymentData.productos no están definidos.');
        return;
      }

      const productos = paymentData.productos.map(producto => {
        let costeAdicionalModificado = producto.coste_adicional;

        if (producto.cantidad === 1 && producto.coste_adicional > 0) {
          costeAdicionalModificado = 0;
        } else if (producto.cantidad >= 2 && producto.coste_adicional > 0) {
          costeAdicionalModificado = (producto.cantidad - 1) * 2000;
        }

        return {
          id_producto: producto.id,
          titulo: producto.titulo,
          precio: producto.precio,
          cantidad: producto.cantidad,
          coste_adicional: costeAdicionalModificado
        };
      });

      const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
      const totalAPagar = parseInt(paymentData.precio_total, 10) + parseInt(paymentData.precio_comuna, 10) + totalCosteAdicional;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            nombre: paymentData.nombre,
            rut: paymentData.rut,
            telefono: paymentData.telefono,
            correo: paymentData.correo,
            precio_total: paymentData.precio_total,
            precio_comuna: paymentData.precio_comuna,
            total_pagar: totalAPagar,
            medio_pago: paymentData.medioPago,
            productos: JSON.stringify(productos),
            update_stock: true,
            direccion: paymentData.direccion,
            comuna: paymentData.comuna,
            region: paymentData.region.nombre,
            ...(compraID && { compra_id: compraID })
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Error en la solicitud a ${url}:`, response.statusText, errorText);
          throw new Error('Error en la solicitud: ' + response.statusText);
        }

        const result = await response.json();
        if (result.success) {
          return result;
        } else {
          console.error(`Error al actualizar datos en ${url}:`, result.error || 'Error desconocido');
        }
      } catch (error) {
        console.error(`Error en la solicitud a ${url}:`, error.message);
      }
    };

    // Verificar si el proceso ha pasado por el Redirector
    const paymentStatus = localStorage.getItem('payment_status');

    if (paymentStatus !== 'approved') {
      navigate('/');
    } else {
      const storedPaymentData = localStorage.getItem('paymentData');
      if (storedPaymentData) {
        const paymentDataParsed = JSON.parse(storedPaymentData);
        setPaymentData(paymentDataParsed);

        // Verificar si hay productos antes de enviar al backend
        if (paymentDataParsed.productos && paymentDataParsed.productos.length > 0) {
          enviarDatosAlBackend('https://milockers.cl/api/actualizar_compra.php', paymentDataParsed)
            .then(async (result) => {
              if (result && result.compra_id) {
                const compraID = result.compra_id;

                const comprobanteUrl = paymentDataParsed.region.nombre === 'Metropolitana de Santiago'
                  ? 'https://milockers.cl/api/comprobante_completo.php'
                  : 'https://milockers.cl/api/comprobante_incompleto.php';

                await enviarDatosAlBackend(comprobanteUrl, paymentDataParsed, compraID);
              }
            })
            .catch(error => {
              console.error('Error al enviar datos al backend:', error);
            });
        } else {
          console.error('Error: paymentData.productos no contiene productos.');
        }
      }

      // Limpiar localStorage después de procesar los datos
      localStorage.removeItem('payment_status');
      localStorage.removeItem('paymentData');
    }

    // Redirigir a la página principal después de 5 segundos
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" color="success.main">
        ¡Compra aprobada!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Gracias por su compra. Su transacción ha sido exitosa.
      </Typography>

      <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
        Será redirigido a la página principal en unos momentos...
      </Typography>
    </div>
  );
}

export default Aprobado;
