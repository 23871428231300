import React from 'react'; 
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

// Importa los logos
import IsotipoMilockers from '../Image/Footer/Milockers_isotipo.svg';
// import TictokLogo from '../Image/Footer/tiktok.svg'; 
import FacebookLogo from '../Image/Footer/facebook.svg'; 
import InstagramLogo from '../Image/Footer/instagram.svg'; 
import WebPayLogo from '../Image/Footer/webpay-cl.png';
import MercadoPago from '../Image/Footer/mercadopago.svg';

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <Box mt={5} py={3} px={2} bgcolor="#FED898" color="black" boxShadow={2}>
      {/* Contenedor principal del footer */}
      <Grid container spacing={4} alignItems="center" justifyContent="center">

        {/* Sección del logo de Milockers y redes sociales */}
        <Grid item xs={12} sm={3} textAlign={isSmallScreen ? 'center' : 'left'}>
          {/* Logo de Milockers */}
          <Box mb={isSmallScreen ? 2 : 0} mx={isSmallScreen ? 0 : 8}>
            <img src={IsotipoMilockers} alt="Milockers Logo" height="100" />
          </Box>

          {/* Íconos de redes sociales */}
          <Box mt={2} textAlign={isSmallScreen ? 'center' : 'left'} mx={isSmallScreen ? 0 : 8}>
            {/* <a href="https://www.tiktok.com/@onloop.cl" target="_blank" rel="noopener noreferrer">
              <img 
                src={TictokLogo} 
                alt="Tictok" 
                height="40" 
                style={{ marginRight: '8px', filter: 'invert(1)' }} 
              />
            </a> */}
            <a href="https://web.facebook.com/www.milockers.cl" target="_blank" rel="noopener noreferrer">
              <img 
                src={FacebookLogo} 
                alt="Facebook" 
                height="40" 
                style={{ marginRight: '8px', filter: 'invert(1)' }} 
              />
            </a>
            <a href="https://www.instagram.com/milockers/" target="_blank" rel="noopener noreferrer">
              <img 
                src={InstagramLogo} 
                alt="Instagram" 
                height="40" 
                style={{ filter: 'invert(1)' }} 
              />
            </a>
          </Box>
        </Grid>

        {/* Sección de medios de pago */}
        <Grid item xs={12} sm={3} textAlign="center">
          <Box mx={isSmallScreen ? 0 : 4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700,
              }}
            >
              Medios de Pago
            </Typography>
            {/* Contenedor para las imágenes en fila */}
            <Box 
              style={{ 
                display: 'flex', 
                flexDirection: 'row', // Cambia la dirección a fila
                justifyContent: 'center', 
                alignItems: 'center', 
                gap: '30px', // Espaciado horizontal entre imágenes
              }}
            >
              <img
                src={WebPayLogo}
                alt="WebPay"
                style={{ maxWidth: '130px', maxHeight: '130px', width: 'auto', height: 'auto' }}
              />
              <img
                src={MercadoPago}
                alt="MercadoPago"
                style={{ 
                  maxWidth: '130px', 
                  maxHeight: '130px', 
                  width: 'auto', 
                  height: 'auto', 
                  filter: 'invert(1)', // Invierte los colores
                }}
              />
            </Box>
          </Box>
        </Grid>


      </Grid>

      {/* Derechos reservados y enlaces */}
      <Box textAlign="center" mt={3}>
        <Typography variant="body2" sx={{fontFamily: 'Outfit, sans-serif', fontWeight: 700}}>
          © 2024 Milockers. All rights reserved.
          <span style={{ marginLeft: '16px' }}>
            <Link to="/terminos" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
              Términos y condiciones
            </Link>
          </span>
          <span style={{ marginLeft: '16px' }}>
            <Link to="/politicas" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
              Política de cambios y devoluciones
            </Link>
          </span>
          <span style={{ marginLeft: '16px' }}>
            <Link to="/nosotros" style={{ color: 'inherit', textDecoration: 'none', fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
              Nosotros
            </Link>
          </span>
        </Typography>

        {/* Mensaje adicional en pantallas grandes */}
        {!isSmallScreen && (
          <Typography variant="body2" style={{ marginTop: '8px', fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
            Hecho con 🦾 por Milockers
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
