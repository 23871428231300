import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'; // Botón "Siguiente"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Ícono de copiar
import { useNavigate } from 'react-router-dom'; // Para redirigir

function DatosTrans() {
    const datosTransferencia = `
        Milockers spa
        76412553-3
        Banco de Chile
        Cuenta Corriente
        1681292909
        milockers@gmail.com
    `;

    const [copiado, setCopiado] = useState(false);
    const [mostrarSiguiente, setMostrarSiguiente] = useState(false);
    const navigate = useNavigate();

    // Mostrar el botón "Siguiente" automáticamente después de 5 segundos al cargar el componente
    useEffect(() => {
        const timer = setTimeout(() => {
            setMostrarSiguiente(true);
        }, 10000); // 5 segundos

        return () => clearTimeout(timer); // Limpiar el temporizador si el componente se desmonta
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(datosTransferencia.trim());
        setCopiado(true);
        setMostrarSiguiente(true); // Mostrar botón "Siguiente" al copiar los datos
        setTimeout(() => setCopiado(false), 2000); // Desaparecer el estado de copiado después de 2 segundos
    };

    const handleNext = () => {
        navigate('/aprobado-transferencia'); // Redirigir a la siguiente página o componente
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f4f4f4'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    maxWidth: '400px',
                    backgroundColor: '#fff'
                }}
            >
                <Typography 
                    variant="h4" 
                    component="h1" 
                    gutterBottom
                    sx={{ fontWeight: 700, fontFamily: 'Outfit, sans-serif' }}
                >
                    ¡Tu compra está casi lista!
                </Typography>
                <Typography 
                    variant="body1" 
                    component="p" 
                    sx={{ mt: 2, fontWeight: 400, fontFamily: 'Outfit, sans-serif' }}
                >
                    Ahora completa la transferencia con los siguientes datos
                </Typography>
                <Typography 
                    variant="body1" 
                    component="p" 
                    sx={{ 
                        mt: 1, 
                        whiteSpace: 'pre-wrap', 
                        fontWeight: 400, 
                        fontFamily: 'Outfit, sans-serif' 
                    }} // whiteSpace: 'pre-wrap' preserva los saltos de línea
                >
                    Milockers spa
                    <br />
                    76412553-3
                    <br />
                    Banco de Chile
                    <br />
                    Cuenta Corriente N° 1681292909
                    <br />
                    milockers@gmail.com
                </Typography>
                
                {/* Botón para copiar los datos */}
                <Tooltip title={copiado ? "¡Copiado!" : "Copiar datos"}>
                    <IconButton 
                        onClick={handleCopy} 
                        sx={{ mt: 2 }}
                    >
                        <ContentCopyIcon />
                        <Typography 
                            variant="body2" 
                            sx={{ ml: 1, fontWeight: 400, fontFamily: 'Outfit, sans-serif' }}
                        >
                            Copiar datos
                        </Typography>
                    </IconButton>
                </Tooltip>

                {/* Botón "Siguiente", visible 5 segundos después o tras copiar */}
                {mostrarSiguiente && (
                    <Button 
                        variant="contained" 
                        sx={{ mt: 3, fontWeight: 400, fontFamily: 'Outfit, sans-serif' }} 
                        onClick={handleNext}
                    >
                        Siguiente
                    </Button>
                )}
            </Paper>
        </Box>
    );
}

export default DatosTrans;
