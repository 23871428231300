import React, { useEffect } from 'react';
import './main.css';
import Navbar from './Componentes/Navbar';
import ItemListContainer from './Componentes/Productos/ItemListContainer';
import ItemDetailContainer from './Componentes/Productos/ItemDetailContainer';
import { BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import Nosotros from './Componentes/Nosotros';
import Contacto from './Componentes/Contacto';
import { CartProvider } from './Context/CardContext';
import Inicio from './Componentes/Inicio/Inicio';
import Footer from './Componentes/Footer';
import ConfirmacionCompra from './Componentes/Compra/ConfirmacionCompra';
import Login from './Componentes/Login';
import Blog from './Componentes/Blog';
import { UserProvider } from './Context/UserContext';
import ScrollToTop from './Utils/ScrollToTop';
import TermsOfService from './Componentes/Policy/TermsOfService';
import PrivacyPolicy from './Componentes/Policy/PrivacyPolicy';
import PoliticaCambio from './Componentes/Policy/Politicas_cambios_devoluciones';
import ReactGA from 'react-ga4';

import Aprobado from './Componentes/Resultado/MercadoPago/Aprobado';
import Fallido from './Componentes/Resultado/MercadoPago/Fallido';
import Pendiente from './Componentes/Resultado/MercadoPago/Pendiente';
import Datos from './Componentes/Datos';
import Pedidos from './Componentes/Pedidos';
import AprobadoTrans from './Componentes/Resultado/Transferencia/AprobadoTrans';
import DatosTrans from './Componentes/Resultado/Transferencia/DatosTrans';
import AprobadoWeb from './Componentes/Resultado/WebPay/Aprobado';
import RechazadoWeb from './Componentes/Resultado/WebPay/Rechazado';
import Redirector from './Componentes/Resultado/WebPay/Redirector';
import CanceladoWeb from './Componentes/Resultado/WebPay/Cancelado';


// Hook para rastrear el cambio de rutas
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
}

function AppRoutes() {
  usePageTracking();

  const excludedRoutes = [
    '/confirmacion-compra',
    '/error-compra',
    '/pending-compra',
    '/aprobado-transferencia',
    '/datos-transferencia',
    '/aprobado-webpay',
    '/rechazado-webpay',
    '/cancelado-webpay',
    '/redirector'
  ];

  const location = useLocation();
  const shouldShowLayout = !excludedRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowLayout && <Datos />} 
      {shouldShowLayout && <Navbar />}
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/item/:id" element={<ItemDetailContainer />} />
        <Route path="/productos" element={<ItemListContainer />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/pedidos" element={<Pedidos />} />
        <Route path="/login" element={<Login />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/confirmacion" element={<ConfirmacionCompra />} />
        <Route path="/iniciar-sesion" element={<Login />} />
        <Route path="/terminos" element={<TermsOfService />} />
        <Route path="/politicas" element={<PoliticaCambio />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/redirector" element={<Redirector />} />
        <Route path="/aprobado-webpay" element={<AprobadoWeb />} />
        <Route path="/rechazado-webpay" element={<RechazadoWeb />} />
        <Route path="/cancelado-webpay" element={<CanceladoWeb />} />
        <Route path="/confirmacion-compra" element={<Aprobado />} />
        <Route path="/error-compra" element={<Fallido />} />
        <Route path="/pending-compra" element={<Pendiente />} />
        <Route path="/aprobado-transferencia" element={<AprobadoTrans />} />
        <Route path="/datos-transferencia" element={<DatosTrans />} />
      </Routes>
      {shouldShowLayout && <Footer />}
    </>
  );
}

function App() {
  return (
    <div>
      <UserProvider>
        <CartProvider>
          <BrowserRouter>
            <ScrollToTop />
            <AppRoutes />
          </BrowserRouter>
        </CartProvider>
      </UserProvider>
    </div>
  );
}

export default App;
