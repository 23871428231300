import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Nosotros = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: '2rem',
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          ¡Somos Milockers!
        </Typography>
        
        <Typography 
          variant="body1" 
          paragraph 
          sx={{  
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
        Somos Milockers, una empresa familiar dedicada a la fabricación de lockers de calidad, donde hermanos, padres y madres trabajan juntos cada día para ser un referente nacional en soluciones de almacenamiento. Nos enorgullece ofrecer productos duraderos, seguros y con una alta calidad que acompañan a nuestros clientes durante toda la vida.        </Typography>
        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Somos una PYME que provee lockers a otras PYMEs y sectores como la industria minera, embotelladoras, colegios, gimnasios, estadios, clubes deportivos y cuerpos de bomberos en todo el país. Con esfuerzo y dedicación, nos aseguramos de que nuestros lockers sean confiables, consistentes y capaces de satisfacer las necesidades de clientes exigentes.        </Typography>
        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
        En Milockers, fabricamos lockers nacionales con el conocimiento y la experiencia que solo un experto puede ofrecer. Nuestros productos son ideales para cualquier entorno, ya sea un gimnasio, oficina, escuela o industria. En cada locker, ponemos calidad, durabilidad y seguridad como prioridades. Milockers es un referente nacional en la fabricación de casilleros para todo tipo de instalaciones, ofreciendo productos resistentes que se ajustan a las necesidades más exigentes.        </Typography>   
        {/* <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400,
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          En Onloop, creemos en los pequeños detalles que marcan la diferencia. Queremos que cada vez que compres en nuestra tienda online de PYMEs, sientas que estás apoyando a emprendedores locales y recibiendo lo mejor que las PYMEs chilenas tienen para ofrecer. Nos enorgullece trabajar con productos sostenibles que mejoran la vida diaria y apoyan a pequeños productores. ¡Gracias por ser parte de nuestra historia!
        </Typography> */}
      </Box>
    </Container>
  );
};

export default Nosotros;
